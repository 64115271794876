import { ElementType, Suspense, lazy } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
// components
import LoadingScreen from 'components/LoadingScreen';
// layouts
import DashboardLayout from 'layouts/dashboard';
// guards
import GuestGuard from 'guards/GuestGuard';
import AuthGuard from 'guards/AuthGuard';
import RoleBasedGuard from 'guards/RoleBasedGuard';
// utils
import { PERMISSION } from 'utils/constants';
// routes
import { PATH_PAGE } from 'routes/paths';

// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) =>
  (
    <Suspense fallback={<LoadingScreen isDashboard={true} />}>
      <Component {...props} />
    </Suspense>
  );

export default function Router() {
  return useRoutes([
    {
      path: '/',
      element: <Navigate to={PATH_PAGE.project.list} replace />,
    },
    {
      path: 'login',
      element: (
        <GuestGuard>
          <Login />
        </GuestGuard>
      ),
    },
    {
      path: 'sign-up',
      element: (
        <GuestGuard>
          <SignUp />
        </GuestGuard>
      ),
    },
    {
      path: 'forgot-password',
      element: <ForgotPassword />,
    },
    {
      path: '/reset-password/:token',
      element: <ResetPassword />,
    },
    // Admin
    {
      path: 'admin',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to="/admin/system-configuration" replace />, index: true },
        {
          path: 'system-configuration',
          element: (
            <RoleBasedGuard hasContent roles={[PERMISSION.ROLE_ADMIN]}>
              <SystemConfiguration />
            </RoleBasedGuard>
          ),
        },
        {
          path: 'labour',
          element: (
            <RoleBasedGuard hasContent roles={[PERMISSION.ROLE_ADMIN]}>
              <LabourListPage />
            </RoleBasedGuard>
          ),
        },
        {
          path: 'material-list',
          element: (
            <RoleBasedGuard hasContent roles={[PERMISSION.ROLE_ADMIN]}>
              <MaterialList />
            </RoleBasedGuard>
          ),
        },
        {
          path: 'product-template',
          children: [
            {
              element: (
                <RoleBasedGuard hasContent roles={[PERMISSION.ROLE_ADMIN]}>
                  <ProductTemplate />
                </RoleBasedGuard>
              ),
              index: true,
            },
            {
              path: 'new',
              element: (
                <RoleBasedGuard hasContent roles={[PERMISSION.ROLE_ADMIN]}>
                  <ProductTemplateCreate />
                </RoleBasedGuard>
              ),
            },
            {
              path: ':productTemplateId/edit',
              element: (
                <RoleBasedGuard hasContent roles={[PERMISSION.ROLE_ADMIN]}>
                  <ProductTemplateCreate />
                </RoleBasedGuard>
              ),
            },
            {
              path: ':productTemplateId/similar',
              element: (
                <RoleBasedGuard hasContent roles={[PERMISSION.ROLE_ADMIN]}>
                  <ProductTemplateSimilar />
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: 'component-template',
          children: [
            {
              element: (
                <RoleBasedGuard hasContent roles={[PERMISSION.ROLE_ADMIN]}>
                  <ComponentTemplate />
                </RoleBasedGuard>
              ),
              index: true,
            },
            {
              path: 'new',
              element: (
                <RoleBasedGuard hasContent roles={[PERMISSION.ROLE_ADMIN]}>
                  <ComponentTemplateCreate />
                </RoleBasedGuard>
              ),
            },
            {
              path: ':componentTemplateId/edit',
              element: (
                <RoleBasedGuard hasContent roles={[PERMISSION.ROLE_ADMIN]}>
                  <ComponentTemplateCreate />
                </RoleBasedGuard>
              ),
            },
          ],
        },
        // {
        //   path: 'kit-template',
        //   children: [
        //     {
        //       element: (
        //         <RoleBasedGuard hasContent roles={[PERMISSION.ROLE_ADMIN]}>
        //           <KitTemplate />
        //         </RoleBasedGuard>
        //       ),
        //       index: true,
        //     },
        //     {
        //       path: 'new',
        //       element: (
        //         <RoleBasedGuard hasContent roles={[PERMISSION.ROLE_ADMIN]}>
        //           <KitTemplateForm />
        //         </RoleBasedGuard>
        //       ),
        //     },
        //     {
        //       path: ':kitTemplateId/edit',
        //       element: (
        //         <RoleBasedGuard hasContent roles={[PERMISSION.ROLE_ADMIN]}>
        //           <KitTemplateForm />
        //         </RoleBasedGuard>
        //       ),
        //     },
        //   ],
        // },
        {
          path: 'config-template',
          children: [
            {
              element: (
                <RoleBasedGuard hasContent roles={[PERMISSION.ROLE_ADMIN]}>
                  <ConfigTemplate />
                </RoleBasedGuard>
              ),
              index: true,
            },
            {
              path: 'new',
              element: (
                <RoleBasedGuard hasContent roles={[PERMISSION.ROLE_ADMIN]}>
                  <ConfigTemplateNew />
                </RoleBasedGuard>
              ),
            },
            {
              path: ':configTemplateId/edit',
              element: (
                <RoleBasedGuard hasContent roles={[PERMISSION.ROLE_ADMIN]}>
                  <ConfigTemplateNew />
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: 'component-configuration',
          children: [
            {
              element: (
                <RoleBasedGuard hasContent roles={[PERMISSION.ROLE_ADMIN]}>
                  <ComponentConfiguration />
                </RoleBasedGuard>
              ),
              index: true,
            },
            {
              path: 'new',
              element: (
                <RoleBasedGuard hasContent roles={[PERMISSION.ROLE_ADMIN]}>
                  <ComponentConfigurationNew />
                </RoleBasedGuard>
              ),
            },
            {
              path: ':componentConfigurationId/edit',
              element: (
                <RoleBasedGuard hasContent roles={[PERMISSION.ROLE_ADMIN]}>
                  <ComponentConfigurationNew />
                </RoleBasedGuard>
              ),
            },
          ],
        },
      ],
    },
    // tools
    {
      path: 'tools',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to="/tools/transfer-orders" replace />, index: true },
        { path: 'transfer-orders', element: <TransferOrders /> },
      ],
    },
    // Main
    {
      path: '*',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: 'project',
          children: [
            { element: <Navigate to="list" replace />, index: true },
            { path: 'list', element: <ProjectListPage /> },
            { path: ':id', element: <ProjectDetail /> },
          ],
        },
      ],
    },
    {
      path: '*',
      children: [
        { path: '500', element: <Page500 /> },
        { path: '404', element: <Page404 /> },
        { path: '403', element: <Page403 /> },
        { path: '*', element: <Navigate to={PATH_PAGE.project.list} replace /> },
      ],
    },
    { path: '*', element: <Navigate to={PATH_PAGE.project.list} replace /> },
  ]);
}

//ERROR
const Page500 = Loadable(lazy(() => import('pages/error/Page500')));
const Page403 = Loadable(lazy(() => import('pages/error/Page403')));
const Page404 = Loadable(lazy(() => import('pages/error/Page404')));
// AUTHENTICATION
const Login = Loadable(lazy(() => import('pages/auth/Login')));
const SignUp = Loadable(lazy(() => import('pages/auth/SignUp')));
const ForgotPassword = Loadable(lazy(() => import('pages/auth/ForgotPassword')));
const ResetPassword = Loadable(lazy(() => import('pages/auth/ResetPassword')));
// ADMIN
const SystemConfiguration = Loadable(lazy(() => import('pages/SystemConfiguration')));
const MaterialList = Loadable(lazy(() => import('pages/MaterialList')));
const LabourListPage = Loadable(lazy(() => import('pages/LabourListPage')));
// PRODUCT TEMPLATE
const ProductTemplate = Loadable(lazy(() => import('pages/ProductTemplate')));
const ProductTemplateCreate = Loadable(lazy(() => import('pages/ProductTemplateCreate')));
const ProductTemplateSimilar = Loadable(lazy(() => import('pages/ProductTemplateSimilar')));
// CONFIG TEMPLATE
const ConfigTemplate = Loadable(lazy(() => import('pages/ConfigTemplateList')));
const ConfigTemplateNew = Loadable(lazy(() => import('pages/ConfigTemplateNew')));
// COMPONENT CONFIGURATION
const ComponentConfiguration = Loadable(lazy(() => import('pages/ComponentConfigurationList')));
const ComponentConfigurationNew = Loadable(lazy(() => import('pages/ComponentConfigurationNew')));
// COMPONENT TEMPLATE
const ComponentTemplate = Loadable(lazy(() => import('pages/ComponentTemplate')));
const ComponentTemplateCreate = Loadable(lazy(() => import('pages/ComponentTemplateCreate')));
// KIT TEMPLATE
const KitTemplate = Loadable(lazy(() => import('pages/kit/KitTemplate')));
const KitTemplateForm = Loadable(lazy(() => import('pages/kit/KitTemplateForm')));
// PROJECT
const ProjectListPage = Loadable(lazy(() => import('pages/ProjectListPage')));
const ProjectDetail = Loadable(lazy(() => import('pages/ProjectDetail')));
// TOOLS
const TransferOrders = Loadable(lazy(() => import('pages/TransferOrders')));
